import React from "react";
import Card from "../../components/Card/Card";
import InsurerForm from "./InsurerForm";

const CreateInsurerPage = () => {
  return (
    <Card title="Registar Seguradora">
      <InsurerForm
        mode={"CREATE"}
        url={"/insurer"}
        successMessage={"Conta criada com sucesso"}
        onSuccessNavigateTo={"../seguradoras"}
      />
    </Card>
  );
};

export default CreateInsurerPage;
