

import unico from "../../assets/images/unico.jpg";
import ca1 from "../../assets/images/ca1.jpg";
import aa from "../../assets/images/aa.jpg";
import caa2 from "../../assets/images/caa2.jpg";
import amado1 from "../../assets/images/amado1.png";
import mobii from "../../assets/images/mobii.png";
import mobz from "../../assets/images/mobz.png";

import soares1 from "../../assets/images/soares1.jpg";
import rocha from "../../assets/images/rocha.jpg";
import wa1 from "../../assets/images/wa1.jpg";
import texeira from "../../assets/images/texeira.jpg";

import anel2 from "../../assets/images/anel2.jpg";
import anel3 from "../../assets/images/anel3.jpg";
import anel4A from "../../assets/images/anel4A.jpg";
import aaa from "../../assets/images/aaa.jpg";
import aaa1 from "../../assets/images/aaa1.jpg";

const car = [
    {
      urls: aa,
    },
    {
      urls: ca1,
     },
    {
      urls: caa2,
    },
   
]

const house = [
 
  {
    urls: soares1,
   },
  {
    urls: rocha,
  },
  {
    urls: wa1,
  },
  {
    urls: texeira,
  },

]

const casamento = [
 
   {
    urls: anel2,
   },
  {
    urls: anel3,
  },
  {
    urls: anel4A,
  },
  {
    urls: aaa,
  },
  {
    urls: aaa1,
  },

]

const mobilia = [
 
  {
    urls: amado1,
   },
  {
    urls: mobii,
  },
  {
    urls: mobz,
  },

]

const outro = [
  {
    urls: aa,
  },

  {
    urls: soares1,
   },
  {
    urls: unico,
   },
   {
    urls: anel2,
   },
]

export {
  car,
  house,
  casamento,
  mobilia,
  outro,
};
