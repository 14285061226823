import { React } from "react";
import Card from "../../components/Card/Card";
import { useParams, useLocation } from "react-router-dom";
import InsuranceBenefitsForm from "./InsuranceBenefitsForm";

const UpdateInsuranceBenefitPage = () => {
  const params = useParams();
  const { state } = useLocation();
  return (
    <>
      <Card title="Actualizar Beneficio de Seguro">
        <InsuranceBenefitsForm
          mode={"UPDATE"}
          url={"/insurancebenefitts/" + state.taxId}
          successMessage={"Conta criada com sucesso"}
          onSuccessNavigateTo={"../actualizar-beneficio/" + state.taxId}
          insurerId={state.insurerId}
          insuranceId={state.insuranceId}
          taxId={state.taxId}
        />
      </Card>
    </>
  );
};

export default UpdateInsuranceBenefitPage;
