import React, { useState, useEffect, useContext } from "react";
import {
  FileUploader,
  ModalWrapper,
  ModalBody,
  ModalFooter,
  Checkbox,
  TextInput,
  Row,
  Column,
  Select,
  SelectItem,
  Tile,
  FormGroup,
} from "carbon-components-react";
import http from "../../http-common";
import { Navigate } from "react-router-dom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const ContractModal = (simulationData) => {
  const [initalData, setInitialData] = useState("");
  const [numberOfChances, setnumberOfChances] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numberOfChancesIndetityCard, setnumberOfChancesIndetityCard] =
    useState(3);

  // TODO state
  const { state } = useLocation();

  const [numberOfChancesNuit, setnumberOfChancesNuit] = useState(3);
  const [numberOfChancesListOfWorkers, setnumberOfChancesListOfWorkers] =
    useState(3);
  const [checked, isChecked] = useState(false);
  const navigate = useNavigate();
  const [nuit, setNuit] = useState(null);
  const [identityCard, setidentityCard] = useState(null);
  const [listOfWorkers, setlistOfWorkers] = useState(null);
  const uploadedIdentityCard = identityCard !== null;
  const uploadedNuit = nuit !== null;
  const uploadedWorksList = listOfWorkers !== null;

  const checkBox = async (e) => {
    if (e == false) {
      isChecked(0);
    } else {
      isChecked(1);
    }
  };

  const onFileChange1 = (event) => {
    if (numberOfChancesIndetityCard > 0) {
      const File = event.target.files[0];
      setidentityCard(File);
    }
  };

  const onFileChange2 = (event) => {
    if (numberOfChancesNuit > 0) {
      const File = event.target.files[0];
      setNuit(File);
    }
  };

  const onFileChange3 = (event) => {
    if (numberOfChancesListOfWorkers > 0) {
      const File = event.target.files[0];
      setNuit(File);
    }
  };
  useEffect(() => {
    // console.log("SIMULATION", simulationData.simulationData);
  }, []);
  const handleSubmittal = async () => {
    const userLocal = JSON.parse(localStorage.getItem("principal"));

    const response = await http.post(
      "/financing",
      simulationData.simulationData
    );
    console.log("RESPONSE simulator data", response);

    if (response.status === 200) {
      navigate("../pagamentos", {
        state: {
          premier: simulationData.simulationData.premier,
          simulationId: response.data.id,
          userId: userLocal.id,
        },
      });
      console.log("dados ",state)
      //window.location.href = "./pagamentos";
    }
    setnumberOfChances(numberOfChances - 1);
    console.log("Chances => ", numberOfChances);
    if (numberOfChances === 0) {
      window.location.href = "./simulador";
    }
  };
  return (
    <>
      <ModalWrapper
        buttonTriggerText="Contratar"
        modalHeading="Termos e Condições"
        primaryButtonText="Submeter"
        modalLabel=""
        secondaryButtonText="Cancelar"
        onPrimaryButtonClick={handleSubmittal}
        handleSubmit={() => handleSubmittal()}
      >
        <ModalBody>
          <p>
            Os Termos e Condições são um documento essencial que visa auxiliar
            na resolução e prevenção de problemas. Eles desempenham um papel
            fundamental em diversos cenários, possibilitando a construção de uma
            base sólida para a sua proteção e segurança.
          </p>
          <Row>
            <Column>
              <Checkbox
                labelText="Concordo com os termos e Condições"
                id="businessType"
                name="businessType"
                onChange={checkBox}
              />
            </Column>
          </Row>

          {checked === 1 && (
            <FormGroup className="section-header">
              <Row>
                <Column>
                  <FileUploader
                    accept={[".jpg", ".pdf"]}
                    buttonKind="tertiary"
                    buttonLabel="Carregar BI"
                    filenameStatus="edit"
                    iconDescription="Adding file"
                    labelDescription="O tamanho máximo é de 20MB"
                    labelTitle="Somente o Bilhete de Identificação"
                    onChange={onFileChange1}
                    disabled={numberOfChances === 0}
                  />

                  {uploadedIdentityCard ? (
                    <p>Arquivo selecionado: {identityCard.name}</p>
                  ) : (
                    <p>Nenhum arquivo carregado ainda</p>
                  )}
                </Column>
                <Column>
                  <FileUploader
                    accept={[".jpg", ".pdf"]}
                    buttonKind="tertiary"
                    buttonLabel="Carregar NUIT"
                    filenameStatus="edit"
                    iconDescription="Adding file"
                    labelDescription="O tamanho máximo é de 20MB"
                    labelTitle="Somente o NUIT"
                    onChange={onFileChange2}
                    disabled={numberOfChances === 0}
                  />

                  {uploadedNuit ? (
                    <p>Arquivo selecionado: {nuit.name}</p>
                  ) : (
                    <p>Nenhum arquivo carregado ainda</p>
                  )}
                </Column>
              </Row>

              {initalData === "Automóvel" && (
                <Row>
                  <Column>
                    <FileUploader
                      accept={[".jpg", ".pdf"]}
                      buttonKind="tertiary"
                      buttonLabel="Carregar Carta de Condução"
                      filenameStatus="edit"
                      iconDescription="Adding file"
                      labelDescription="O tamanho máximo é de 20MB"
                      labelTitle="Somente Carta de Condução"
                    />
                  </Column>
                  <Column>
                    <FileUploader
                      accept={[".jpg", ".pdf"]}
                      buttonKind="tertiary"
                      buttonLabel="Carregar Livrete"
                      filenameStatus="edit"
                      iconDescription="Adding file"
                      labelDescription="O tamanho máximo é de 20MB"
                      labelTitle="Somente o Livrete"
                    />
                  </Column>
                </Row>
              )}

              {initalData ===
                "Acidente de Trabalho e doenças profissionais" && (
                <Row>
                  <Column>
                    <FileUploader
                      accept={[".jpg", ".pdf", "..xlsx"]}
                      buttonKind="tertiary"
                      buttonLabel="Carregar a lista nominal de trabalhadores"
                      filenameStatus="edit"
                      iconDescription="Adding file"
                      labelDescription="O tamanho máximo é de 20MB"
                      labelTitle="Somente a lista nominal de trabalhadores"
                      onChange={onFileChange3}
                      disabled={numberOfChances === 0}
                    />

                    {uploadedNuit ? (
                      <p>Arquivo selecionado: {uploadedWorksList.name}</p>
                    ) : (
                      <p>Nenhum arquivo carregado ainda</p>
                    )}
                  </Column>
                </Row>
              )}

              {initalData === "Proteção do meu Business" && (
                <Row>
                  <Column>
                    <FileUploader
                      accept={[".jpg", ".pdf", "..xlsx"]}
                      buttonKind="tertiary"
                      buttonLabel="Carregar a lista de fornecedores"
                      filenameStatus="edit"
                      iconDescription="Adding file"
                      labelDescription="O tamanho máximo é de 20MB"
                      labelTitle="Somente a lista de fornecedores"
                    />
                  </Column>
                </Row>
              )}

              {(initalData === "Automóvel" ||
                initalData === "Proteção do meu Business" ||
                initalData === "Habitação") && (
                <Column>
                  <FileUploader
                    accept={[".jpg", ".pdf"]}
                    buttonKind="tertiary"
                    buttonLabel="Carregar o Título de Propriedade"
                    filenameStatus="edit"
                    iconDescription="Adding file"
                    labelDescription="O tamanho máximo é de 20MB"
                    labelTitle="Somente o Título de Propriedade"
                  />
                </Column>
              )}
            </FormGroup>
          )}
        </ModalBody>
      </ModalWrapper>
    </>
  );
};
export default ContractModal;
