import React, { useEffect, useContext } from "react";
import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
  DatePicker,
  DatePickerInput,
} from "carbon-components-react";
import { toast } from "react-toastify";
import { set, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { insuranceTaxSchema } from "../../services/SchemaService";
import http from "../../http-common";
import { useState } from "react";
import { ArrowRight24 } from "@carbon/icons-react";
import ClipLoader from "react-spinners/ClipLoader";
import { yupResolver } from "@hookform/resolvers/yup";

const InsuranceTaxForm = ({
  mode,
  url,
  successMessage,
  insurerId,
  insuranceId,
  insuranceTaxId,
  taxId,
}) => {
  const [opMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [insurancetax, setInsurancetax] = useState({
    id: 0,
    taxPer: 0,
    taxDate: "",
    descriptiom: "",
    insuranceType: "",
    insurance: { id: insuranceId, type: "" },
  });
  const [type, setType] = useState([]);
  // const [insuranceType, setInsuranceType] = useState([]);

  const getSchema = () => {
    switch (mode) {
      case "CREATE":
        return insuranceTaxSchema;
      case "UPDATE":
        return insuranceTaxSchema;
      default:
        return insuranceTaxSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onBlur" });

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      var result = null;
      if (opMode === "UPDATE") {
        result = await http.post(url, data);

        toast.success("Taxa de suguro Actualizada com sucesso!");
        setTimeout(() => {
          setLoading(false);
        }, 2000);

        // navigate("../actualizar-taxa/" + result?.data?.id);
        navigate("../actualizar-taxa/" + taxId, {
          state: {
            taxId: taxId,
            insuranceId: insuranceId,
            insurerId: insurerId,
          },
        });

        //   console.log("result", result)
      } else {
        result = await http.post(url, data);
        toast.success("Taxa de suguro registada  com sucesso!");

        setTimeout(() => {
          setLoading(false);
        }, 2000);
        navigate("../actualizar-seguro/" + insuranceId, {
          state: { taxId: insurancetax.id, insuranceId: insuranceId },
        });
      }

      toast.success(successMessage);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (opMode === "UPDATE") {
      const fetchData = async () => {
        const result = null;
        try {
          setLoading(true);

          const result = await http.get(`/insurance/taxes/${taxId}`);

          console.log("Log", result);
          const fields = [
            "id",
            "taxPer",
            "taxDate",
            "description",
            "insurance",
          ];
          fields.forEach((field) => setValue(field, insurancetax[field]));
          setInsurancetax(result.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [opMode]);

  useEffect(() => {
    reset(insurancetax);
  }, [insurancetax]);

  useEffect(() => {
    (async () => {
      let response = await getInsurances();
      if (response.success) {
        setType(response.data);
      }
    })();
  }, []);

  const getInsurances = async () => {
    try {
      const response = await http.get(`/insurance/insurer/${insurerId}`);
      let items = response.data.map((e) => {
        var subType = e.subType != null ? "-" + e.subType : "";
        return {
          id: e.id,
          text: e.type + " " + subType,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  function onTypeChange(e) {
    insurancetax.insurance.id = e.selectedItem.id;
    setValue("insurancetax.insurance.id", e.selectedItem.id);
    console.log("insurancetax.insurance.id", insurancetax.insurance.id);
  }
  const setTaxDate = (e) => {
    setValue("taxDate", e.target.value);
    insurancetax.taxDate = e.target.value;
    console.log("Tax date", insurancetax.taxDate);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <Dropdown
              id="insurance.id"
              titleText="Tipo de Seguro"
              label={
                opMode == "UPDATE" ? insurancetax.insurance.type : "Selecione"
              }
              items={type}
              onChange={onTypeChange}
              name="insurance.id"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.type?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="taxPer"
              labelText="Taxa de Seguro"
              {...register("taxPer")}
            />
            <p className="field-error">{errors.taxPer?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="description"
              labelText="Descricao"
              maxLength={200}
              {...register("description")}
            />
            <p className="field-error">{errors.taxPer?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6}>
            {opMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data"
                  id="taxDate"
                  name="taxDate"
                  value={insurancetax.taxDate}
                  {...register("taxDate")}
                  onSelect={setTaxDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data"
                  id="taxDate"
                  name="taxDate"
                  {...register("taxDate")}
                  onSelect={setTaxDate}
                />
              </DatePicker>
            )}
          </Column>
        </Row>
        <Row className="mt-2">
          <Column sm={12} md={6} lg={6}>
            <ButtonSet>
              {!isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  Salvar
                </Button>
              )}
              {isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  <ClipLoader
                    isLoading={isLoading}
                    color={"#fff"}
                    size={20}
                    padding={"50px"}
                  />
                </Button>
              )}
              <Button
                onClick={() =>
                  navigate("../actualizar-seguro/" + insuranceId, {
                    state: { taxId: insurancetax.id, insuranceId: insuranceId },
                  })
                }
                className="mt-2 btn-fluid"
              >
                Voltar para seguro
              </Button>
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default InsuranceTaxForm;
