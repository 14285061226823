import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Edit24 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  OverflowMenu,
  OverflowMenuItem,
} from "carbon-components-react";

import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";
import { toast } from "react-toastify";
const tableHeaders = [
  {
    header: "Tipo",
    key: "type",
  },
  {
    header: "Descricao",
    key: "description",
  },
  {
    header: "Taxa",
    key: "tax",
  },
  {
    header: "Operacoes",
    key: "actions",
  },
];

const LinkList = ({ url }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
  </ul>
);

const InsurancePage = ({ insurerId }) => {
  const [insurances, setInsurances] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);

  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const response = await http.get(`/insurance/insurer/${insurerId}`);
      //  const response = await http.get(`/taxinsurance/${insurerId}`);
      getInsuranceData(response);
    } catch (e) {
      toast.error(e);
    }
  };

  const getInsuranceData = (response) => {
    try {
      const data = response?.data?.map((insurance) => {
        var subType = insurance.subType != null ? "-" + insurance.subType : "";

        return {
          id: insurance.id,
          type: insurance.type + " " + subType,
          description: insurance.description,
          tax: insurance.tax,
          actions: (
            <OverflowMenu flipped>
              <OverflowMenuItem
                key="optionOne"
                itemText="Editar"
                onClick={() =>
                  navigate("../actualizar-seguro/" + insurance.id, {
                    state: { insuranceId: insurance.id, insurerId: insurerId },
                  })
                }
              />
            </OverflowMenu>
          ),
        };
      });
      setInsurances(data);
      setCount(response.data.totalElements);
    } catch (e) {
      toast.error(e);
    }
  };
  const onPageChange = async ({ page, pageSize }) => {
    try {
      const pag = page - 1;
      console.log("pag", pag, "Pagesize", pageSize);
      const response = await http.get(`/insurance/insurer/${insurerId}`);
      //const response = await http.get(`/taxinsurance/${insurerId}`);
      getInsuranceData(response);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card title="Seguros">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-seguro/" + insurerId)}
          renderIcon={Add16}
        >
          Registar Tipo Seguro
        </Button>
      </div>
      <DataTable
        rows={insurances}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar"></TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default InsurancePage;
