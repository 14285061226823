const Purpose = [
    {id: '1',text: 'Automóvel'},
    {id: '2',text: 'Habitação'},
    { id: '3', text: 'Acidente de Trabalho e doenças profissionais' },
    { id: '4', text: 'Empregada Doméstica' }, 
    { id: '5',text: 'Viagem'},
    { id: '6',text: 'Proteção do meu Business'},
    { id: '7',text: 'Vida (Benefecio funeral)'}
];
const InsuranceAgainst = [
    {id: '1',text: 'Terceiro'},
    {id: '2',text: 'Todos os riscos'}
];
const MeansTransport = [
    {id: '1',text: 'Aéreo'},
    {id: '2',text: 'Marítimo'},
    { id: '3', text: 'Rodoviário' },
    { id: '4', text: 'Comboio/Metro' }, 
];
const NOfMonths = [
    {id: '1',value: 6 } ,
    {id: '2',value: 12 }, 
    { id: '3', value: 24 }, 
    { id: '4', value: 36 }, 
    { id: '5',value: 48 }, 
    { id: '6',value: 60 }
];

const CarTypes = [
    {id: '1',text: 'Ligeiro' } ,
    {id: '2',text: 'Pesado' }, 
    { id: '3', text: 'Motorizada' }, 
    { id: '4', text: 'Mini-Bus' }, 
    
];


export {
    Purpose,
    NOfMonths,
    InsuranceAgainst,
    MeansTransport,
    CarTypes
  };