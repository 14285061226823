import { React } from "react";
import Card from "../../components/Card/Card";
import { useParams,useLocation } from "react-router-dom";
import InsuranceTaxForm from "./InsuranceTaxForm";

const UpdateInsuranceTaxPage = () => {
  const params = useParams();
  const {state} =useLocation();
  return (
    <>
      <Card title="Actualizar Tipo de Seguro">
        <InsuranceTaxForm
          mode={"UPDATE"}
          url={"/insurancetaxes/" + state.taxId}
          successMessage={"Conta criada com sucesso"}
          onSuccessNavigateTo={"../taxas"}
          insurerId={state.insurerId}
          insuranceId={state.insuranceId}
          taxId={state.taxId}
        />
      </Card>
    </>
  );
};

export default UpdateInsuranceTaxPage;
