import { Pagination } from "carbon-components-react";
import React, { useState } from "react";

const TablePagination = ({ totalItems, onPageChange, currentPageSize }) => {

    return (
        <div>
            <Pagination
                totalItems={totalItems}
                backwardText="Próxima"
                forwardText="Anterior"
                pageSize={currentPageSize}
                pageSizes={[10, 20, 50]}
                itemsPerPageText="Itens por página"
                itemRangeText={(min, max, total) => `${min}–${max} de ${total} itens`}
                pageRangeText={(current, total) => `de ${total} ${total === 1 ? 'página' : 'páginas'}`}
                onChange={onPageChange}
            />
        </div>
    )

}

export default TablePagination;
