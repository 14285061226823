import {
  Button,
  Row,
  Column,
} from "carbon-components-react";
import { ArrowRight24 } from "@carbon/icons-react";
import "../../app.scss";
import React from "react";

import { User32 } from "@carbon/icons-react";
import { Home32 } from "@carbon/icons-react";
import { Purchase32 } from "@carbon/icons-react";
import { UserMultiple32 } from "@carbon/icons-react";
import LoginForm from "../Login/LoginForm";

const HomePage = () => {

  return (
    <>
      <div className="hero">
        <div className="bx--grid">
          <Column sm={12} md={12} lg={10} xlg={10} style={{ margin: "auto" }}>
            <Row>
              <Column sm={12} md={12} lg={6} xlg={6}>
                <h1 className="title">Teu seguro digital, adquira já</h1>
                {/* <p className="content" style={{ fontSize: "1.2rem" }}>
                  A plataforma financiamento.co.mz é um sistema de simulação de crédito,
                  com as respectivas taxas dos diferentes bancos e microcréditos no mercado
                  financeiro para diferentes fins, tais como carro, casa, casamento, e outros.
                </p> */}
                <br />
                <p className="content mt-2" style={{ fontSize: "1.2rem" }}>
                A nova forma de adquirir o seu seguro
                </p>
                <br/>
                <Button
                  //type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-1"
                  href="/regista-te"
                >
                  Regista-te
                </Button>
              </Column>
              <Column sm={12} md={12} lg={2} xlg={2}></Column>
              <Column sm={12} md={12} lg={4} xlg={4} style={{ margin: "auto" }}>
                {" "}
                <LoginForm></LoginForm>
              </Column>
            </Row>
          </Column>
        </div>
      </div>
      <div className="boxes">
        <div className="bx--grid">
          <div className="content-area">
            <Column sm={12} md={12} lg={10} xlg={10} style={{ margin: "auto" }}>
              {/* <h3
                style={{ color: "#f8ac00", textAlign: "center" }}
                className="mt-2 mb-2"
              >
                <strong>Conheça os Tipos de Empréstimos</strong>
              </h3> */}
              {/* <Row>
                <Column>
                  <div class="box">
                    <div>
                      <h2>
                        <User32 />
                      </h2>
                      <h5>Empréstimo Pessoal</h5>
                      <p>
                        Um empréstimo pessoal , também conhecido como empréstimo
                        sem garantia, não requer que se coloque algo que se
                        possui como garantia. Terá mais hipóteses de ser aceite
                        para um empréstimo pessoal se tiver uma boa pontuação de
                        crédito e um historial de que mostre aos credores que
                        pode gerir o crédito de forma sensata.
                      </p>
                    </div>
                  </div>
                </Column>
                <Column className="content">
                  <div class="box">
                    <div>
                      <h2>
                        <Home32 />
                      </h2>
                      <h5>Empréstimo Garantido</h5>
                      <p>
                        Com um empréstimo garantido ou um empréstimo para Casa,
                        é necessário constituir um activo que possua como
                        garantia de que pagará de volta o que pediu emprestado.
                        É importante que faça os seus pagamentos de empréstimo a
                        tempo
                      </p>
                    </div>
                  </div>
                </Column>
                <Column className="content">
                  <div class="box">
                    <div>
                      <h2>
                        <Purchase32 />
                      </h2>
                      <h5>Empréstimo Consolidação</h5>
                      <p>
                        Empréstimo consolidação é uma opção se tiver múltiplas
                        dívidas de empréstimos pessoais, cartões de crédito ou
                        cartões de loja. Um empréstimo de consolidação de
                        dívidas permite-lhe mover os seus saldos de dívida para
                        um único empréstimo. Ao ter um pagamento mensal em vez
                        de vários, pode achar mais fácil gerir os pagamentos da
                        sua dívida.
                      </p>
                    </div>
                  </div>
                </Column>
                <Column className="content">
                  <div class="box">
                    <div>
                      <h2>
                        <UserMultiple32 />
                      </h2>
                      <h5>Empréstimo Fiador</h5>
                      <p>
                        Se tiver uma pontuação de crédito baixa, um empréstimo
                        fiador pode ser adequado para si. Com este tipo de
                        empréstimo, precisa de um fiador, que promete fazer os
                        seus pagamentos por si se não conseguir acompanhar o seu
                        ritmo. As pessoas pedem frequentemente a um familiar,
                        mas o seu fiador não tem de ser um familiar.
                      </p>
                    </div>
                  </div>
                </Column>
              </Row> */}
            </Column>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
