import React, { useEffect, useContext } from "react";
import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
  DatePicker,
  DatePickerInput,
} from "carbon-components-react";
import { toast } from "react-toastify";
import { set, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { insuranceTaxSchema } from "../../services/SchemaService";
import http from "../../http-common";
import { useState } from "react";
import { ArrowRight24 } from "@carbon/icons-react";
import ClipLoader from "react-spinners/ClipLoader";
import { yupResolver } from "@hookform/resolvers/yup";

const InsuranceBenefitsForm = ({
  mode,
  url,
  successMessage,
  insurerId,
  insuranceId,
  insuranceTaxId,
  taxId,
}) => {
  const [opMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [insuranceBenefitis, setInsuranceBenefitis] = useState({
    id: 0,
    type: "",
    containign: "",
    descriptiom: "",
    //insuranceType: "",
    insurance: { id: insuranceId, type: "" },
  });
  const [type, setType] = useState([]);
  const [bentype, seBentType] = useState([]);

  // const getSchema = () => {
  //   switch (mode) {
  //     case "CREATE":
  //       return insuranceTaxSchema;
  //     case "UPDATE":
  //       return insuranceTaxSchema;
  //     default:
  //       return insuranceTaxSchema;
  //   }
  // };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      var result = null;
      if (opMode === "UPDATE") {
        result = await http.post(url, data);

        toast.success("Beneficio de suguro Actualizada com sucesso!");
        setTimeout(() => {
          setLoading(false);
        }, 2000);

        // navigate("../actualizar-taxa/" + result?.data?.id);
        navigate("../actualizar-beneficio/" + taxId, {
          state: {
            taxId: taxId,
            insuranceId: insuranceId,
            insurerId: insurerId,
          },
        });

        //   console.log("result", result)
      } else {
        result = await http.post(url, data);
        toast.success("Taxa de suguro registada  com sucesso!");

        setTimeout(() => {
          setLoading(false);
        }, 2000);
        navigate("../actualizar-seguro/" + insuranceId, {
          state: { taxId: insuranceBenefitis.id, insuranceId: insuranceId },
        });
      }

      toast.success(successMessage);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (opMode === "UPDATE") {
      const fetchData = async () => {
        const result = null;
        try {
          setLoading(true);

          const result = await http.get(`/insurancebenefitts/${taxId}`);

          console.log("Log", result);
          const fields = ["id", "type", "description"];
          fields.forEach((field) => setValue(field, insuranceBenefitis[field]));
          setInsuranceBenefitis(result.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [opMode]);

  useEffect(() => {
    reset(insuranceBenefitis);
  }, [insuranceBenefitis]);

  //fetch Benefity Type
  useEffect(() => {
    (async () => {
      let response = await fetchInsuranceBenefitType();
      if (response.success) {
        seBentType(response.data);
      }
      // console.log("Tipos de seguros => ",response.data)
    })();
  }, []);

  const fetchInsuranceBenefitType = async () => {
    try {
      const response = await http.get("/INSURANCE_BENEFITS");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  function onBenTypeChange(e) {
    insuranceBenefitis.type = e.selectedItem.text;
    setValue("type", e.selectedItem.text);
    console.log("type", insuranceBenefitis.type);
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <Dropdown
              id="type"
              titleText="Tipo de Benefico"
              label={opMode == "UPDATE" ? insuranceBenefitis.type : "Selecione"}
              items={bentype}
              onChange={onBenTypeChange}
              name="type"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.type?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="description"
              labelText="Descricao"
              maxLength={200}
              {...register("description")}
            />
            <p className="field-error">{errors.taxPer?.message}</p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column sm={12} md={6} lg={6}>
            <ButtonSet>
              {!isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  Salvar
                </Button>
              )}
              {isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  <ClipLoader
                    isLoading={isLoading}
                    color={"#fff"}
                    size={20}
                    padding={"50px"}
                  />
                </Button>
              )}
              <Button
                onClick={() =>
                  navigate("../actualizar-beneficio/" + insuranceId, {
                    state: {
                      taxId: insuranceBenefitis.id,
                      insuranceId: insuranceId,
                    },
                  })
                }
                className="mt-2 btn-fluid"
              >
                Voltar para seguro
              </Button>
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default InsuranceBenefitsForm;
