import { React, useState } from "react";
import Card from "../../components/Card/Card";
import InsurerForm from "./InsurerForm";
import { Tabs, Tab } from "carbon-components-react";
import { useParams, useLocation } from "react-router-dom";
import InsurancePage from "../Insurance/InsurancePage";

const UpdateInsurerPage = () => {
  const params = useParams();
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <Card title="Actualizar Seguradora">
        <InsurerForm
          mode={"UPDATE"}
          url={"/insurer/" + state.insurerId}
          successMessage={"Conta criada com sucesso"}
          onSuccessNavigateTo={"../seguradoras"}
          insurerId={state.insurerId}
        />

        <hr></hr>
        <Tabs
          className="Navmargin"
          selected={activeTab}
          onSelectionChange={handleTabChange}
        >
          <Tab label="">
            <InsurancePage insurerId={state.insurerId} />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default UpdateInsurerPage;
