import React from "react";
import Card from "../../components/Card/Card";
import InsuranceForm from "./InsuranceForm";
import { useParams } from "react-router-dom";

const CreateInsurancePage = () => {
  const params = useParams();
  return (
    <Card title="Registar Tipo de Seguro">
      <InsuranceForm
        mode={"CREATE"}
        insurerId={params.id}
        url={"/insurance"}
        successMessage={"Conta criada com sucesso"}
        onSuccessNavigateTo={"../actualizar-seguradora/" + params.id}
      />
    </Card>
  );
};

export default CreateInsurancePage;
