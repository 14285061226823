import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  ButtonSet,
  Form,
  TextInput,
  DatePicker,
  DatePickerInput,
  Row,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Dropdown,
  Column,
  Select,
  SelectItem,
  Tile,
  ComboBox,
} from "carbon-components-react";
import React, { useState, useEffect, useContext } from "react";
import { submitSimulationSchema } from "../../services/SchemaService";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import Card from "../../components/Card/Card";
import ShowSlider from "../../components/Slider/ShowSlider";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import ContractModal from "../Modal/Modal";
import { InsuranceAgainst, MeansTransport, CarTypes } from "./PurposeData";

import * as XLSX from "xlsx";
import File from "../../car_test.xlsx";

const BeneficiarySimulation = ({ mode = "CREATE", url = "financing" }) => {
  const navigate = useNavigate();
  const [initalData, setInitialData] = useState("");
  const [showInfo, setShowInfo] = useState("");
  const [monthlySalary = 0, setMonthlySalary] = useState("");
  const [numberOfTravelers = 0, setNumberOfTravelers] = useState("");
  const [travelDuration = 0, setTravelDuration] = useState("");
  const [housingValue = 0, sethHousingValue] = useState("");
  const [fillingValue = 0, sethFillingValue] = useState("");
  const [numberOfEmployees = 0, setNumberOfEmployees] = useState("");
  const [formMode] = useState(mode);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [buildingValue = 0, setBuildingValue] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [districtData, setDistrictData] = useState([]);

  //Life
  const [fillValue, setFillValue] = useState();
  const [numberHouseholds, setNumberHouseholds] = useState();
  const [mobileValue, setMobileValue] = useState();
  const [amountCapital, setAmountCapital] = useState();

  //Car
  const [insuranceAgainst, setInsuranceAgainst] = useState("");
  const [vehicleValue, setVehicleValue] = useState("");
  // dates
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // TODO File verify
  const [seguradoras, setSeguradoras] = useState([]);
  const params = useParams();
  const [loanRequest, setLoanRequest] = useState({});
  const { authenticated, loginOfLoan, client, user } = useContext(AuthContext);
  const [cid] = useState(params.id);
  const [status, setStatus] = useState(null);
  const [addsStatus, setAddsStatus] = useState(null);
  const [cars, setCars] = useState([]);
  const [loan, setLoan] = useState({
    code: "",
    purpose: "",
    clientId: null,
    yearManufacture: null,
    carRegistration: null,

    brand: null,
    model: null,
    capacity: 0,
    typeVehicle: null,
    vehicleValue: null,
    namePersonInCharge: null,
    typeHousing: null,
    numberWorkers: null,
    activity: null,

    grossMonthlySalary: null,
    monthlyAllowance: null,

    address: null,
    merchandiseVolumePerMonth: null,
    securityMeasures: null,
    accidentRecord: null,
    merchandiseInventory: null,
    buildingValue: null,

    housingValue: null,
    fillingValue: null,
    provinceId: null,
    districtId: null,

    insuranceAgainst: null,
    endDate: null,
    destination: null,
    meansTransport: null,
    startDate: null,
    numberOfTravelers: null,
    travelDuration: null,

    beneficiary_id: 0,

    //Life
    fillValue: null,
    numberHouseholds: null,
    mobileValue: null,
    amountCapital: null,
    carsId: 0,

    engineNumber: null,
    chassisNumber: null,
  });

  const tableHeaders = [
    {
      header: "Seguradora",
      key: "insurerName",
    },
    {
      header: "Prêmio",
      key: "primeirconv",
    },
    {
      header: "Operacoes",
      key: "actions",
    },
  ];

  const securityMeasuresOption = [
    "Selecione",
    "Existencia de extintores",
    "Sistemas de incendio",
    "Sistema de alrme",
    "Seguranca fisica 24Horas",
    "Cerca Electrca",
  ];

  const [matricula, setMatricula] = useState("");
  const [modelo, setModelo] = useState("");

  const [modelcar, setModelCar] = useState();
  const [manYear, setManYear] = useState();
  var [numberOfSeats, setNumberOfSeats] = useState(0);

  var [beneficiary_id, setbeneficiary_id] = useState(0);

  const [val, setVal] = useState(0);
  const [teste, setTeste] = useState();

  const [beneficiaries, setBeneficiaries] = useState([]);

  useEffect(() => {
    reset(loanRequest);
  }, [loanRequest]);

  const getSchema = () => {
    switch (mode) {
      case "CREATE":
        return submitSimulationSchema;
      case "UPDATE":
        return submitSimulationSchema;
      default:
        return submitSimulationSchema;
    }
  };

  const clearFields = async (e) => {
    setValue("yearManufacture", null);
    setValue("carRegistration", null);
    setValue("brand", null);
    setValue("model", null);
    setValue("capacity", null);
    setValue("typeVehicle", null);
    setValue("vehicleValue", null);
    setValue("namePersonInCharge", null);
    setValue("homeAddress", null);
    setValue("numberOfTravelers", null);
    setValue("typeHousing", null);
    setValue("housingValue", null);
    setValue("numberWorkers", null);
    setValue("activity", null);
    setValue("grossMonthlySalary", null);
    setValue("monthlyAllowance", null);
    setValue("destination", null);
    setValue("meansTransport", null);
    setValue("travelDuration", null);
    setValue("address", null);
    setValue("merchandiseVolumePerMonth", null);
    setValue("securityMeasures", null);
    setValue("accidentRecord", null);
    setValue("merchandiseInventory", null);
    setValue("buildingValue", null);
    setValue("travelDuration", null);
    setValue("numberHouseholds", null);
    setValue("insuranceAgainst", null);
    setValue("beneficiary_id", null);
  };

  const modalFormPresence = (taxDTO) => {
    const userLocal = JSON.parse(localStorage.getItem("principal"));
    const selectedInsurer = {
      ...taxDTO,
      grossMonthlySalary: monthlySalary,
      numberWorkers: numberOfEmployees,
      id: null,
      client: { id: userLocal.id },
      purpose: initalData,
      insurer: { id: taxDTO.insurerId },

      housingValue: housingValue,
      fillingValue: fillingValue,
      buildingValue: buildingValue,
      beneficiary_id: beneficiary_id,
    };
    console.log("selectedInsurer", selectedInsurer);
    return <ContractModal simulationData={selectedInsurer} />;
  };

  const onDateSelectStart = async (data) => {
    loan.startDate = data.target.value;
    setValue("startDate", data.target.value);
    setStartDate(startDate);
    console.log("data de inicio => ", data.target.value);
  };

  const onDateSelectEnd = async (data) => {
    loan.endDate = data.target.value;
    setValue("endDate", data.target.value);
    setEndDate(endDate);
    console.log("data de fim => ", data.target.value);
  };

  const getAllInsurances = async () => {
    try {
      const response = await http.post("/simulation/" + initalData, {
        grossMonthlySalary: monthlySalary,
        numberWorkers: numberOfEmployees,
        type: initalData,
        purpose: initalData,
        vehicleValue: vehicleValue,
        numberOfTravelers: numberOfTravelers,
        travelDuration: travelDuration,
        insuranceAgainst: insuranceAgainst,
        housingValue: housingValue,
        fillingValue: fillingValue,
        buildingValue: buildingValue,
        fillValue: fillValue,
        numberHouseholds: numberHouseholds,
        mobileValue: mobileValue,
        amountCapital: amountCapital,

        monthlyAllowance: getValues("monthlyAllowance"),
        activity: getValues("activity"),
        carRegistration: getValues("carRegistration"),
        brand: getValues("brand"),
        model: getValues("model"),
        yearManufacture: getValues("yearManufacture"),
        typeVehicle: getValues("typeVehicle"),
        destination: getValues("destination"),
        meansTransport: getValues("meansTransport"),
        securityMeasures: getValues("securityMeasures"),
        capacity: getValues("capacity"),
        beneficiary_id: getValues("beneficiary_id"),
        engineNumber: getValues("engineNumber"),
        chassisNumber: getValues("chassisNumber"),
      });

      const data = response.data.map((insurance) => ({
        ...insurance,
        actions: modalFormPresence(insurance),
      }));

      setSeguradoras(data);
    } catch (error) {
      //  toast.error(error.response?.data.message);
      toast.error("Digite um numero valido");
    }
  };

  const onDateSelect = async (data) => {
    try {
      var star = new Date(loan.startDate);
      console.log("Stat", star);

      var end = new Date(loan.endDate);
      console.log("end", end);

      var diff = end - star;
      var diffmilyear = 1000 * 60 * 60 * 24;
      var diffyear = Math.round(diff / diffmilyear);
      setValue("travelDuration", diffyear);
      setTravelDuration(diffyear);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  const calculateInsurancePremium = async () => {
    await getAllInsurances();
    setStatus(true);
    switch (initalData) {
      case "Automóvel":
        break;
      case "Habitação":
        setStatus(true);
        break;
      case "Viagem":
        break;
      case "Vida":
        break;
      case "Proteção do meu Business":
        setStatus(true);
        break;

      case "Acidente de Trabalho e Doenças Profissionais":
        setStatus(true);
        break;

      case "Empregada Doméstica":
        break;

      default:
        break;
    }
  };

  const {
    reset,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getSchema()),
    mode: "onBlur",
  });

  const onChangeField = (e) => {
    loan.numberWorkers = e.target.value;
    setValue("numberWorkers", e.target.value);
    setNumberOfEmployees(e.target.value);
  };
  const onChangeFieldMonthlySalary = (e) => {
    loan.grossMonthlySalary = e.target.value;
    setValue("grossMonthlySalary", e.target.value);
    setMonthlySalary(e.target.value);
  };

  const onChangeFieldTravelDuration = (e) => {
    loan.numberOfTravelers = e.target.value;
    setValue("numberOfTravelers", e.target.value);
    setNumberOfTravelers(e.target.value);
  };

  //Home Insurance
  const onHousingValueChangeField = (e) => {
    loan.housingValue = e.target.value;
    setValue("housingValue", e.target.value);

    sethHousingValue(e.target.value);
  };

  const onFillingValueChangeField = (e) => {
    loan.fillingValue = e.target.value;
    setValue("fillingValue", e.target.value);

    sethFillingValue(e.target.value);
  };

  const fetchProvinces = async () => {
    try {
      const response = await http.get("/provinces");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  useEffect(() => {
    (async () => {
      let response = await fetchProvinces();
      if (response.success) {
        setProvinces(response.data);
      }
    })();
  }, []);

  function onProvinceChange(e) {
    loan.provinceId = e.selectedItem.id;
    setDistrictData(e.selectedItem.children);
    let response = e.selectedItem.children.map((c) => {
      return { id: c.id, text: c.description };
    });
    setValue("provinceId", e.selectedItem.id);

    setDistricts(response);
  }
  function onDistrictChange(e) {
    loan.districtId = e.selectedItem.id;
    setValue("districtId", e.selectedItem.id);
  }

  function onInsuranceAgainstChange(e) {
    loan.insuranceAgainst = e.selectedItem.text;
    setValue("insuranceAgainst", e.selectedItem.text);
    console.log("Tipo selecionado: ", e.selectedItem.text);
    setInsuranceAgainst(e.selectedItem.text);
  }

  useEffect(() => {
    (async () => {
      let response = await getBeneficiaries();
      if (response.success) {
        setBeneficiaries(response.data);
        console.log("Beneficiary", response.data);
      }
    })();
  }, []);

  console.log("cid", cid);
  const getBeneficiaries = async () => {
    const userLocal = JSON.parse(localStorage.getItem("principal"));
    try {
      const response = await http.get(
        "/beneficiary/beneficiary/client/" + userLocal.id
      );
      console.log("userLocal.id", userLocal.id);
      let items = response.data.map((e) => {
        return {
          id: e.id,
          text: e.firstName + " " + e.surName,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };
  // Car insurance

  function onVehicleValueChange(e) {
    loan.vehicleValue = e.target.value;
    setValue("vehicleValue", e.target.value);
    setVehicleValue(e.target.value);
  }
  // <----------------------  end car insurance --------------------->

  // Life insurance

  function onFillValueChange(e) {
    setFillValue(e.target.value);
    setValue("fillValue", e.target.value);
  }

  function onNumberHouseholdsChange(e) {
    setNumberHouseholds(e.target.value);
    setValue("numberHouseholds", e.target.value);
  }

  function onMobileValueChange(e) {
    setMobileValue(e.target.value);
    setValue("mobileValue", e.target.value);
  }

  function onAmountCapitalChange(e) {
    setAmountCapital(e.target.value);
    setValue("amounCapital", e.target.value);
  }

  // <----------------- end life insurance ------------------>

  const setPurposeValue = async (e) => {
    setValue("purpose", e.selectedItem.text);
    setValue("simulationDate", new Date().toISOString().slice(0, 10));
    setInitialData(e.selectedItem.text);
  };

  const fetchCars = async () => {
    try {
      const data = await readExcel();
      console.log("DataUse", data);
      setCars(
        data.map((car) => ({
          id: car.id_trim,
          text:
            car.make +
            " " +
            car.model +
            " " +
            car.year_from +
            " " +
            car.series +
            " " +
            car.trim,
          model: car.model,
          brand: car.brand,
          manYear: car.year_from,
          numberOfSeats: car.number_of_seats,
        }))
      );
    } catch (error) {
      console.error("Erro ao ler o arquivo Excel:", error);
    }
  };
  //Car Model
  useEffect(() => {
    fetchCars();
  }, []);

  const readExcel = async () => {
    try {
      const response = await fetch(File);
      const buffer = await response.arrayBuffer();
      const wb = XLSX.read(buffer, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      return data;
    } catch (error) {
      throw error;
    }
  };

  function onTypeVehicle(e) {
    loan.typeVehicle = e.selectedItem.id;
    setValue("typeVehicle", e.selectedItem.id);
  }

  const handleCarsChange = (event) => {
    loan.carsId = event.selectedItem.id;
    setValue("carsId", event.selectedItem.id);
    setValue("yearManufacture", event.selectedItem.manYear);

    if (event.selectedItem.numberOfSeats) {
      numberOfSeats = parseInt(event.selectedItem.numberOfSeats);
      setValue("capacity", numberOfSeats);
    } else {
      numberOfSeats = 0;
      setValue("capacity", numberOfSeats);
    }

    setValue("model", event.selectedItem.model);
    loan.brand = event.selectedItem.brand;
    setValue("brand", event.selectedItem.brand);
  };

  useEffect(() => {
    onDateSelect();
  });

  function onBeneficiaryChange(e) {
    loan.beneficiary_id = e.selectedItem.id;
    setbeneficiary_id(e.selectedItem.id);
    console.log("beneficiary_id2", beneficiary_id);
  }

  useEffect(() => {
    const getInsuranceTypes = async () => {
      const response = await http.get("/INSURANCE_TYPE");
      const dataSelect = response.data.map((value) => {
        return { id: value.id, text: value.description, type: value.type };
      });

      setInsuranceTypes(dataSelect);
    };
    getInsuranceTypes();
    const fetchData = async () => {
      if (formMode === "CREATE") {
        try {
          const result = await http.get(`/client/${cid}`);
          const udata = {
            clientId: result.data.id,
          };
          const fields = ["clientId"];
          fields.forEach((field) => setValue(field, loanRequest[field]));
          setValue("clientId", udata.clientId);
          setLoanRequest(udata);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      }
    };
  }, [formMode, showInfo]);

  return (
    <div style={{ padding: "3rem 0" }}>
      <div className="bx--grid">
        <Column sm={12} md={12} lg={12} xlg={12} style={{ margin: "auto" }}>
          <div className="bx--row p-3">
            {addsStatus === true ? (
              <div className="bx--col-sm-12 bx--col-lg-12">
                <ShowSlider purpose={loan.purpose} />
              </div>
            ) : (
              ""
            )}

            <div
              className="bx--col-sm-12 bx--col-lg-12"
              style={{ marginTop: "5px" }}
            >
              <Card title="Formulário">
                <Form>
                  <Row>
                    <Column
                      sm={6}
                      md={6}
                      lg={6}
                      className="required"
                      style={{ marginBottom: "30px" }}
                    >
                      <Dropdown
                        id="beneficiary_id"
                        titleText="Beneficiario"
                        label="Selecione"
                        items={beneficiaries}
                        //{...register("beneficiary_id")}
                        onChange={onBeneficiaryChange}
                        name="beneficiary_id"
                        itemToString={(item) => (item ? item.text : "")}
                      />
                      <p className="field-error">
                        {errors.beneficiary_id?.message}
                      </p>
                    </Column>
                    <Column
                      sm={6}
                      md={6}
                      lg={6}
                      className="required"
                      style={{ marginBottom: "30px" }}
                    >
                      <Dropdown
                        id="purpose"
                        titleText="Tipo de Seguro"
                        label="Selecione"
                        items={insuranceTypes}
                        {...register("purpose")}
                        onChange={setPurposeValue}
                        name="purpose"
                        itemToString={(item) => (item ? item.text : "")}
                      />
                      <p className="field-error">{errors.purpose?.message}</p>
                    </Column>
                  </Row>
                  {initalData === "Automóvel" && CarForm()}
                  {(initalData === "Habitação" ||
                    initalData === "Proteção do meu Business") &&
                    (clearFields(),
                    (
                      <>
                        <Row>
                          <Column>
                            <Dropdown
                              id="provinceId"
                              titleText="Provincia"
                              label="Selecione"
                              items={provinces}
                              onChange={onProvinceChange}
                              name="provinceId"
                              itemToString={(item) => (item ? item.text : "")}
                            />

                            <p className="field-error">
                              {errors.provinceId?.message}
                            </p>
                          </Column>
                          <Column>
                            <Dropdown
                              id="districtId"
                              titleText="Distrito"
                              label="Selecione"
                              items={districts}
                              onChange={onDistrictChange}
                              name="districtId"
                              itemToString={(item) => (item ? item.text : "")}
                            />
                            <p className="field-error">
                              {errors.districtId?.message}
                            </p>
                          </Column>
                        </Row>
                        <Row>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Endereço"
                              name="homeAddress"
                              id="homeAddress"
                              maxLength={100}
                              {...register("homeAddress")}
                            />
                            <p className="field-error">
                              {errors.homeAddress?.message}
                            </p>
                          </Column>
                          <Column>
                            <Select
                              id="securityMeasures"
                              labelText="Medidas de Seguranca"
                              name="securityMeasures"
                              variant="outlined"
                              {...register("securityMeasures")}
                            >
                              {securityMeasuresOption.map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </Select>
                            <p className="field-error">
                              {errors.securityMeasures?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                              labelText="Valor do Imovel"
                              name="housingValue"
                              id="housingValue"
                              maxLength={50}
                              onChange={onHousingValueChangeField}
                            />
                            <p className="field-error">
                              {errors.housingValue?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                              labelText="Valor da Recheio"
                              name="fillingValue"
                              id="fillingValue"
                              maxLength={50}
                              onChange={onFillingValueChangeField}
                            />
                            <p className="field-error">
                              {errors.fillingValue?.message}
                            </p>
                          </Column>
                        </Row>
                      </>
                    ))}

                  {(initalData ===
                    "Acidente de Trabalho e Doenças Profissionais" ||
                    initalData === "Empregada Doméstica") &&
                    (clearFields(),
                    (
                      <>
                        <Row>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Número de Trabalhadores"
                              name="numberWorkers"
                              id="numberWorkers"
                              maxLength={8}
                              onChange={onChangeField}
                            />
                            <p className="field-error">
                              {errors.numberWorkers?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Actividade"
                              name="activity"
                              id="activity"
                              maxLength={8}
                              {...register("activity")}
                            />
                            <p className="field-error">
                              {errors.activity?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Salário Bruto Mensal"
                              name="grossMonthlySalary"
                              id="grossMonthlySalary"
                              maxLength={8}
                              onChange={onChangeFieldMonthlySalary}
                            />
                            <p className="field-error">
                              {errors.grossMonthlySalary?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Subsídio Mensal"
                              name="monthlyAllowance"
                              id="monthlyAllowance"
                              maxLength={8}
                              {...register("monthlyAllowance")}
                            />
                            <p className="field-error">
                              {errors.monthlyAllowance?.message}
                            </p>
                          </Column>
                        </Row>
                      </>
                    ))}
                  {initalData === "Viagem" &&
                    (clearFields(),
                    (
                      <>
                        <Row>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Destino"
                              name="destination"
                              id="destination"
                              maxLength={8}
                              {...register("destination")}
                            />
                            <p className="field-error">
                              {errors.destination?.message}
                            </p>
                          </Column>

                          <Column>
                            <Select
                              id="meansTransport"
                              labelText="Meio de Transporte"
                              name="meansTransport"
                              {...register("meansTransport")}
                            >
                              <SelectItem text="" value="" />
                              {MeansTransport.map((p) => (
                                <SelectItem
                                  text={p.text}
                                  value={p.text}
                                  key={p.id}
                                />
                              ))}
                            </Select>
                            <p className="field-error">
                              {errors.meansTransport?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <DatePicker
                              datePickerType="single"
                              locale="pt"
                              dateFormat="d/m/Y"
                            >
                              <DatePickerInput
                                id="startDate"
                                placeholder="dd/mm/yyyy"
                                labelText="Data de Inicio"
                                name="startDate"
                                onSelect={onDateSelectStart}
                                maxLength={10}
                              ></DatePickerInput>
                              <p className="field-error">
                                {errors.startDate?.message}
                              </p>
                            </DatePicker>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <DatePicker
                              datePickerType="single"
                              locale="pt"
                              dateFormat="d/m/Y"
                            >
                              <DatePickerInput
                                id="endDate"
                                placeholder="dd/mm/yyyy"
                                labelText="Data de fim"
                                name="endDate"
                                onSelect={onDateSelectEnd}
                                maxLength={10}
                              ></DatePickerInput>
                              <p className="field-error">
                                {errors.endDate?.message}
                              </p>
                            </DatePicker>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Numero de viajantes"
                              name="numberOfTravelers"
                              id="numberOfTravelers"
                              maxLength={8}
                              onChange={onChangeFieldTravelDuration}
                            />
                            <p className="field-error">
                              {errors.numberOfTravelers?.message}
                            </p>
                          </Column>
                        </Row>
                      </>
                    ))}

                  {initalData === "Vida" &&
                    (clearFields(),
                    (
                      <>
                        <Row>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Numéro de Agregados Familiares"
                              name="numberHouseholds"
                              id="numberHouseholds"
                              maxLength={8}
                              onChange={onNumberHouseholdsChange}
                            />
                            <p className="field-error">
                              {errors.numberHouseholds?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6}>
                            <TextInput
                              labelText="Capital vs Beneficios"
                              name="amountCapital"
                              id="amountCapital"
                              maxLength={8}
                              onChange={onAmountCapitalChange}
                            />
                            <p className="field-error">
                              {errors.amounCapital?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                              labelText="Valor do Imovel"
                              name="mobileValue"
                              id="mobileValue"
                              maxLength={15}
                              onChange={onMobileValueChange}
                            />
                            <p className="field-error">
                              {errors.housingValue?.message}
                            </p>
                          </Column>
                          <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                              labelText="Valor da Recheio"
                              name="fillValue"
                              id="fillValue"
                              maxLength={15}
                              onChange={onFillValueChange}
                            />
                            <p className="field-error">
                              {errors.fillingValue?.message}
                            </p>
                          </Column>
                        </Row>
                      </>
                    ))}
                  <Row className="mt-2">
                    <Column sm={12} md={6} lg={6}>
                      <ButtonSet>
                        <Button
                          onClick={calculateInsurancePremium}
                          iconDescription="Calcular"
                        >
                          Calcular
                        </Button>
                      </ButtonSet>
                    </Column>
                  </Row>
                </Form>
                {status === true ? (
                  <Column sm={12} md={12} lg={12}>
                    <Tile style={{ position: "center", marginTop: "20px" }}>
                      <p className="card-title ">{initalData}</p>

                      <DataTable
                        rows={seguradoras}
                        headers={tableHeaders}
                        isSortable
                        useZebraStyles
                      >
                        {({ rows, headers, getHeaderProps, getTableProps }) => (
                          <TableContainer>
                            <TableToolbar aria-label="data table toolbar">
                              <TableToolbarContent></TableToolbarContent>
                            </TableToolbar>
                            <Table {...getTableProps()}>
                              <TableHead>
                                <TableRow>
                                  {headers.map((header) => (
                                    <TableHeader
                                      {...getHeaderProps({ header })}
                                    >
                                      {header.header}
                                    </TableHeader>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => (
                                  <TableRow key={row.id}>
                                    {row.cells.map((cell) => (
                                      <TableCell key={cell.id}>
                                        {cell.value}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </DataTable>
                    </Tile>
                  </Column>
                ) : (
                  ""
                )}
              </Card>
            </div>
          </div>
        </Column>
      </div>
    </div>
  );

  function CarForm() {
    return (
      <>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="Matrícula"
              name="carRegistration"
              id="carRegistration"
              maxLength={11}
              {...register("carRegistration")}
            />
            <p className="field-error">{errors.carRegistration?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <ComboBox
              id="brand"
              titleText="Marca"
              label="Selecione"
              items={cars}
              {...register("brand")}
              name="brand"
              onChange={handleCarsChange}
              itemToString={(item) => (item ? item.text : "")}
            />
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="Modelo"
              name="model"
              id="model"
              maxLength={4}
              value={modelcar}
              disabled
              {...register("model")}
            />
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="Ano de Fabrico"
              name="yearManufacture"
              id="yearManufacture"
              maxLength={4}
              value={manYear}
              {...register("yearManufacture")}
              disabled
            />
            <p className="field-error">{errors.yearManufacture?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="Lotação"
              name="capacity"
              id="capacity"
              {...register("capacity")}
              disabled={numberOfSeats !== 0}
            />
            <p className="field-error">{errors.capacity?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="Número de Motor"
              name="engineNumber"
              id="engineNumber"
              maxLength={10}
              {...register("engineNumber")}
            />
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="Número de Chassis"
              name="chassisNumber"
              id="chassisNumber"
              maxLength={13}
              {...register("chassisNumber")}
            />
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="typeVehicle"
              titleText="Tipo de Viatura"
              label="Selecione"
              items={CarTypes}
              {...register("typeVehicle")}
              onChange={onTypeVehicle}
              name="typeVehicle"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.typeVehicle?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              labelText="Valor da Viatura"
              name="vehicleValue"
              id="vehicleValue"
              onChange={onVehicleValueChange}
              maxLength={8}
            />
            <p className="field-error">{errors.vehicleValue?.message}</p>
          </Column>
          <Column>
            <Dropdown
              id="insuranceAgainst"
              titleText="Seguro contra?"
              label="Selecione"
              items={InsuranceAgainst}
              onChange={onInsuranceAgainstChange}
              name="insuranceAgainst"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.insuranceAgainst?.message}</p>
          </Column>
        </Row>
      </>
    );
  }
};

export default BeneficiarySimulation;
