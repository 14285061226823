import React from "react";
import Card from "../../components/Card/Card";
import InsuranceTaxForm from "./InsuranceTaxForm";
import { useParams, useLocation } from "react-router-dom";

const CreateInsuranceTaxPage = () => {
  const params = useParams();
  const {state} =useLocation();

  return (
    <Card title="Registar Taxa de Seguro">
      <InsuranceTaxForm
        mode={"CREATE"}
        url={"/insurancetaxes"}
        successMessage={"Conta criada com sucesso"}
        onSuccessNavigateTo={"../actualizar-taxa/" + params.id}
        insurerId={state.insurerId}
        insuranceId={state.insuranceId}
      
      />
    </Card>
  );
};

export default CreateInsuranceTaxPage;
