import { useState, useEffect } from "react";
import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
} from "carbon-components-react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { insuranceSchema } from "../../services/SchemaService";
import http from "../../http-common";
import { ArrowRight24 } from "@carbon/icons-react";
import ClipLoader from "react-spinners/ClipLoader";
import React from "react";

const InsuranceForm = ({
  mode,
  url,
  successMessage,
  insurerId,
  insuranceId,
}) => {
  const [opMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);

  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState({
    id: 0,
    type: "",
    subType: "",
    description: "",
    insurer: { id: insurerId },
  });
  const [type, setType] = useState([]);
  const [subType, setSubType] = useState([]);

  const getSchema = () => {
    switch (mode) {
      case "CREATE":
        return insuranceSchema;
      case "UPDATE":
        return insuranceSchema;
      default:
        return insuranceSchema;
    }
  };

  const navigate = useNavigate();

  async function onSubmit(data) {
    setisBtnDisabled(true);
    console.log("insurerId", insurerId);
    try {
      setLoading(true);
      var result = null;
      if (opMode === "UPDATE") {
        result = await http.post(url, data);
        toast.success("Seguradora Actualizada com sucesso!");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        navigate("../actualizar-seguro/" + insuranceId, {
          state: { insuranceId: insurance.id, insurerId: insurerId },
        });
      } else {
        result = await http.post(url, data);
        toast.success("Seguradora registada  com sucesso!");

        setTimeout(() => {
          setLoading(false);
        }, 1000);

        navigate("../actualizar-seguradora/" + insurerId, {
          state: { insuranceId: insurance.id, insurerId: insurerId },
        });
      }
      setisBtnDisabled(false);
      toast.success(successMessage);
    } catch (error) {
      toast.error(error);
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (opMode === "UPDATE") {
      const fetchData = async () => {
        const result = null;
        try {
          setLoading(true);
          const result = await http.get(`/insurance/${insuranceId}`);

          const fields = ["id", "type", "description"];
          fields.forEach((field) => setValue(field, insurance[field]));
          setInsurance(result.data);
          //console.log("Data form", result.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [opMode]);

  useEffect(() => {
    reset(insurance);
  }, [insurance]);

  //fetch Insurance Type
  useEffect(() => {
    (async () => {
      let response = await fetchInsuranceType();
      if (response.success) {
        setType(response.data);
      }

      // console.log("Tipos de seguros => ",response.data)
    })();

    (async () => {
      let response = await fetchInsuranceSubType();
      if (response.success) {
        setSubType(response.data);
      }

      // console.log("Tipos de seguros => ",response.data)
    })();
  }, []);

  const fetchInsuranceType = async () => {
    try {
      const response = await http.get("/INSURANCE_TYPE");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  const fetchInsuranceSubType = async () => {
    try {
      const response = await http.get("/INSURANCE_SUBTYPE");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };
  function onTypeChange(e) {
    insurance.type = e.selectedItem.text;
    setValue("type", e.selectedItem.text);
  }

  function onSubTypeChange(e) {
    insurance.subType = e.selectedItem.text;
    setValue("subType", e.selectedItem.text);
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={4} md={6} lg={6}>
            <Dropdown
              id="type"
              titleText="Tipo de Seguro"
              label={opMode == "UPDATE" ? insurance.type : "Selecione"}
              items={type}
              onChange={onTypeChange}
              name="type"
              itemToString={(item) => (item ? item.text : "")}
            />
          </Column>
          <Column sm={4} md={6} lg={6}>
            <Dropdown
              id="subType"
              titleText="Sub Tipo de Seguro"
              label={opMode == "UPDATE" ? insurance.subType : "Selecione"}
              items={subType}
              onChange={onSubTypeChange}
              name="subType"
              itemToString={(item) => (item ? item.text : "")}
            />
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="description"
              labelText="Descricao"
              maxLength={200}
              {...register("description")}
            />
            <p className="field-error">{errors.location?.message}</p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column sm={12} md={6} lg={6}>
            <ButtonSet>
              {!isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  Salvar
                </Button>
              )}
              {isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  <ClipLoader
                    isLoading={isLoading}
                    color={"#fff"}
                    size={20}
                    padding={"50px"}
                  />
                </Button>
              )}
              <Button
                onClick={() =>
                  navigate("../actualizar-seguradora/" + insurerId, {
                    state: { insuranceId: insurance.id, insurerId: insurerId },
                  })
                }
                className="mt-2 btn-fluid"
              >
                Voltar para seguradora
              </Button>
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default InsuranceForm;
