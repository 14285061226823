import React from "react";
import Card from "../../components/Card/Card";
import InsuranceBenefitsForm from "./InsuranceBenefitsForm";
import { useParams, useLocation } from "react-router-dom";

const CreateInsuranceTaxPage = () => {
  const params = useParams();
  const { state } = useLocation();

  return (
    <Card title="Registar Beneficio de Seguro">
      <InsuranceBenefitsForm
        mode={"CREATE"}
        url={"/insurancebenefitts"}
        successMessage={"Conta criada com sucesso"}
        onSuccessNavigateTo={"../actualizar-beneficio/" + params.id}
        insurerId={state.insurerId}
        insuranceId={state.insuranceId}
      />
    </Card>
  );
};

export default CreateInsuranceTaxPage;
