import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import {car, house, casamento, mobilia, outro } from "./AddImage";
import "./slider.css";

const carShow = car.length - 1;
const houseShow = house.length - 1;
const wedShow = casamento.length - 1;
const furnitureShow = mobilia.length - 1;
const otherShow = outro.length - 1;

const ShowSlider = ({
  purpose,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
        setActiveIndex(activeIndex === carShow ? 0 : activeIndex + 1);
        setActiveIndex(activeIndex === houseShow ? 0 : activeIndex + 1);
        setActiveIndex(activeIndex === wedShow ? 0 : activeIndex + 1);
        setActiveIndex(activeIndex === furnitureShow ? 0 : activeIndex + 1);
        setActiveIndex(activeIndex === otherShow ? 0 : activeIndex + 1);
      
    }, 1000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="slider-container">

        {purpose == 'Carro' ?
            <SliderContent 
            activeIndex={activeIndex} 
            AddImage={car} 
            />
        : ""}

        {purpose == 'Casa' ?
            <SliderContent 
            activeIndex={activeIndex} 
            AddImage={house} 
            />
        : ""}

        {purpose == 'Casamento' ?
            <SliderContent 
            activeIndex={activeIndex} 
            AddImage={casamento} 
            />
        : ""}

        {purpose == 'Mobilia' ?
            <SliderContent 
            activeIndex={activeIndex} 
            AddImage={mobilia} 
            />
        : ""}

        {purpose == 'Outro' ?
            <SliderContent 
            activeIndex={activeIndex} 
            AddImage={outro} 
            />
        : ""}
    </div>
  );
}

export default ShowSlider;
