import { Column, Row } from "carbon-components-react";
import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="bx--grid">
          <Column sm={12} md={12} lg={10} xlg={10} style={{ margin: "auto" }}>
            <Row>
              <Column>
                <p>
                  2023 © All rights reserved by |{" "}
                  <a href="http://www.ologa.com/" style={{ color: "#ddd" }}>
                    Ologa – Sistemas Informáticos
                  </a>
                </p>
              </Column>
            </Row>
          </Column>
        </div>
      </div>
    </>
  );
};
export default Footer;
