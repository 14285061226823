import "react-toastify/dist/ReactToastify.css";
import "./app.scss";
import { Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout";
import HomePage from "./pages/Home/HomePage";
import NotFoudPage from "./pages/404/NotFoudPage";
import CompetitorDashboardPage from "./pages/Dashboard/CompetitorDashboardPage";
import { AuthProvider } from "./contexts/AuthContext";
import React, { Suspense, lazy } from "react";
import CreateInsurerPage from "./pages/Insurer/CreateInsurerPage";
import InsurerPage from "./pages/Insurer/InsurerPage";
import InsurancePage from "./pages/Insurance/InsurancePage";
import CreateInsurancePage from "./pages/Insurance/CreateInsurancePage";
import UpdateInsurerPage from "./pages/Insurer/UpdateInsurerPage";
import UpdateInsurancePage from "./pages/Insurance/UpdateInsurancePage";
import CreateInsuranceTaxPage from "./pages/InsuranceTax/CreateInsuranceTaxPage";
import InsuranceTaxPage from "./pages/InsuranceTax/InsuranceTaxPage";
import UpdateInsuranceTaxPage from "./pages/InsuranceTax/UpdateInsuranceTaxPage";
import ListInsuranceTax from "./pages/InsuranceTax/ListInsuranceTax";
import ListInsurance from "./pages/Insurance/ListInsurance";
import BeneficiarySimulation from "./pages/Loan/BeneficiarySimulation";
import CreateInsuranceBenefitsPage from "./pages/InsuranceBenefits/CreateInsuranceBenefitsPage";
import UpdateInsuranceBenefitPage from "./pages/InsuranceBenefits/UpdateInsuranceBenefitPage";

const UsersPage = lazy(() => import("./pages/User/UsersPage"));
const CreateUserPage = lazy(() => import("./pages/User/CreateUserPage"));
const UserProfilePage = lazy(() => import("./pages/User/UserProfilePage"));
const UpdateUserPage = lazy(() => import("./pages/User/UpdateUserPage"));
const UpdatePasswordPage = lazy(() =>
  import("./pages/User/UpdatePasswordPage")
);

const ListOfSimulations = lazy(() => import("./pages/Loan/ListOfSimulations"));

const PaymentsPage = lazy(() => import("./pages/Payments/PatmentPage"));
const ListBeneficiaries = lazy(() => import("./pages/User/ListBeneficiary"));
const Beneficiary = lazy(() => import("./pages/User/Beneficiary"));

const AddBankPage = lazy(() => import("./pages/Bank/AddBankPage"));
const ListBankPage = lazy(() => import("./pages/Bank/ListBankPage"));
const UpdateBankPage = lazy(() => import("./pages/Bank/UpdateBankPage"));
const SimulationReport = lazy(() => import("./pages/Report/SimulationReport"));
const LoanRequestList = lazy(() => import("./pages/Loan/LoanRequestList"));

const AddBankFeePage = lazy(() => import("./pages/BankFee/AddBankFeePage"));
const UpdateBankFeePage = lazy(() =>
  import("./pages/BankFee/UpdateBankFeePage")
);
const ListBankFeePage = lazy(() => import("./pages/BankFee/ListBankFeePage"));

const SignupPage = lazy(() => import("./pages/User/SignupPage"));
const ContactForm = lazy(() => import("./pages/Contact/ContactForm"));
const CommercialBanks = lazy(() =>
  import("./pages/StandardizedConditions/CommercialBanks")
);
const Microfinance = lazy(() =>
  import("./pages/StandardizedConditions/Microfinance")
);
const AddLoanPage = lazy(() => import("./pages/Loan/AddLoanPage"));
const AddLoanRequestPage = lazy(() =>
  import("./pages/Loan/AddLoanRequestPage")
);
const UpdateLoanRequestPage = lazy(() =>
  import("./pages/Loan/UpdateLoanRequestPage")
);
const PasswordRecoveryPage = lazy(() =>
  import("./pages/User/PasswordRecoveryPage")
);

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<PublicLayout />}>
              <Route path="/" element={<HomePage />}></Route>
              <Route path="login" element={<HomePage />} />
              <Route path="regista-te" element={<SignupPage />} />
              <Route path="contactos" element={<ContactForm />} />
              <Route
                path="condicoes-padronizadas/bancos-comerciais"
                element={<CommercialBanks />}
              />
              <Route
                path="condicoes-padronizadas/microfinancas"
                element={<Microfinance />}
              />

              <Route path="loan-form/:id" element={<AddLoanRequestPage />} />
              <Route path="loan-page/:id" element={<LoanRequestList />} />
              <Route
                path="loan-update-page/:id"
                element={<UpdateLoanRequestPage />}
              />
              <Route path="passwordUpdate" element={<UpdatePasswordPage />} />
              <Route
                path="passwordRecovery"
                element={<PasswordRecoveryPage />}
              />
            </Route>
            <Route path="/admin/*" element={<AdminLayout />}>
              <Route path="simulador" element={<AddLoanPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="beneficiary" element={<Beneficiary />} />
              <Route
                path="ListaBeneficiarios"
                element={<ListBeneficiaries />}
              />
              <Route path="listaDeSimulacoes" element={<ListOfSimulations />} />
              <Route path="pagamentos" element={<PaymentsPage />} />
              <Route path="inicio" element={<CompetitorDashboardPage />} />
              <Route path="registar-banco" element={<AddBankPage />} />
              <Route path="actualizar-banco/:id" element={<UpdateBankPage />} />
              <Route path="bancos" element={<ListBankPage />} />
              <Route path="relatorio" element={<SimulationReport />} />
              {/* <Route path="registar-taxa" element={<AddBankFeePage />} /> */}
              {/* <Route
                path="actualizar-taxa/:id"
                element={<UpdateBankFeePage />}
              /> */}
              <Route path="taxas-bancarias" element={<ListBankFeePage />} />
              <Route path="utilizadores" element={<UsersPage />} />
              <Route path="registar-utilizador" element={<CreateUserPage />} />
              <Route
                path="actualizar-utilizador/:id"
                element={<UpdateUserPage />}
              />
              <Route path="minha-conta" element={<UserProfilePage />} />
              {/* Mic bar */}
              {/* //Seguradora */}
              <Route
                path="registar-seguradora"
                element={<CreateInsurerPage />}
              />
              <Route
                path="actualizar-seguradora/:id"
                element={<UpdateInsurerPage />}
              />
              <Route path="seguradoras" element={<InsurerPage />} />

              {/* Seguro */}
              <Route path="registar-seguro" element={<CreateInsurancePage />} />
              <Route
                path="registar-seguro/:id"
                element={<CreateInsurancePage />}
              />
              <Route
                path="actualizar-seguro/:id"
                element={<UpdateInsurancePage />}
              />
              <Route path="consultar-seguros" element={<ListInsurance />} />

              <Route path="seguros" element={<InsurancePage />} />

              {/* //Taxa de seguradora */}
              <Route
                path="registar-taxa"
                element={<CreateInsuranceTaxPage />}
              />
              <Route
                path="registar-taxa/:idInsurer/:idInsurance"
                element={<CreateInsuranceTaxPage />}
              />
              <Route
                path="registar-beneficio/:idInsurer/:idInsurance"
                element={<CreateInsuranceBenefitsPage />}
              />

              <Route
                path="actualizar-taxa/:id"
                element={<UpdateInsuranceTaxPage />}
              />

              <Route
                path="actualizar-beneficio/:id"
                element={<UpdateInsuranceBenefitPage />}
              />
              <Route path="consultar-taxa" element={<ListInsuranceTax />} />
              <Route
                path="simulador/beneficiario"
                element={<BeneficiarySimulation />}
              />
            </Route>
            <Route path="taxas" element={<InsuranceTaxPage />} />
            <Route path="*" element={<NotFoudPage />} />
          </Routes>
        </AuthProvider>
      </Suspense>
    </>
  );
}

export default App;
