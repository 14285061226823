import React, { useContext, useEffect, useState } from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import logo from "../assets/images/financiamentoLogo.png";
import { ToastContainer } from "react-toastify";
import { Row, Column } from "carbon-components-react";
import { AuthContext } from "../contexts/AuthContext";
import menu from "../services/NavigationService";
import Footer from "../pages/Footer/Footer";
import { Logout16, UserAvatarFilledAlt32 } from "@carbon/icons-react";
import useGet from "../hooks/useGet";
const PublicLayout = () => {
  useEffect(() => {
    document.body.classList.remove("admin-page");
    document.body.classList.add("public-page");
  }, []);

  const { authenticated, user, isCompetitor, loading, logout } =
    useContext(AuthContext);
  const params = useParams();
  const [cid] = useState(params.id);

  const nav = menu(String(user?.roleCode));

  return (
    <>
      <>
        <header>
          <div
            className="bx--grid"
            style={{ marginTop: -15, marginBottom: -15 }}
          >
            <Column sm={12} md={12} lg={10} xlg={10} className="header-wrap">
              <div>
                <a href="http://financiamento.co.mz/">
                  {/* <img
                    src={logo}
                    alt="Financiamento"
                    width={120}
                    height={120}
                  /> */}
                </a>
              </div>

              <nav id="navbar" className="navbar">
                {!authenticated && (
                  <ul>
                    <li>

                      {/* <div className="dropdown">
                        <button className="dropbtn">Condições padronizadas
                        </button>
                        <div className="dropdown-content">
                          <a href="/condicoes-padronizadas/bancos-comerciais">dos Bancos Comerciais</a>
                          <a href="/condicoes-padronizadas/microfinancas">de Microfinanças</a>
                        </div>
                      </div> */}

                    </li>

                    <li>
                      <Link to="/login" className="nav-link">
                        Login
                      </Link>
                    </li>

                    <li>
                      <Link to="/regista-te" className="nav-link">
                        Regista-te
                      </Link>
                    </li>

                    <li>
                      <Link to="/contactos" className="nav-link">
                        Contacte-nos
                      </Link>
                    </li>
                  </ul>
                )}

                <ul>
                  {authenticated && isCompetitor &&(
                    <>
                      <h4 className="nameofuser">
                        {user?.firstName + " " + user?.lastName}
                      </h4>
                      <UserAvatarFilledAlt32 />
                      <li>
                        <Link onClick={logout} to={""}>
                          Sair
                          <Logout16 />
                        </Link>
                      </li>
                      <li>
                        <Link to={"/passwordUpdate/" + user?.id}>
                          Alterar Senha
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
                <i className="bi bi-list mobile-nav-toggle"></i>
              </nav>
            </Column>
          </div>
        </header>
        <main className="main">
          {/* <Column sm={12} md={12} lg={10} xlg={10} style={{ margin: "auto" }}> */}
          <Outlet />
          <ToastContainer
            theme="colored"
            position="top-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* </Column> */}
        </main>
        <Footer />
      </>
    </>
  );
};

export default PublicLayout;
