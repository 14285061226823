import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Edit24, Search32 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Row,
  Column,
  TextInput,
  OverflowMenu,
  OverflowMenuItem,
} from "carbon-components-react";

import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form } from "carbon-components-react";

const tableHeaders = [
  {
    header: "Nome",
    key: "name",
  },
  {
    header: "Localizacao",
    key: "location",
  },
  {
    header: "Nuit",
    key: "nuit",
  },
  {
    header: "Referencia",
    key: "accountNumber",
  },
  {
    header: "Operacoes",
    key: "actions",
  },
];

const LinkList = ({ url }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
  </ul>
);

const InsurerPage = () => {
  const [insurers, setInsurers] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchData = async () => {
    try {
      console.log("size", size);
      const response = await http.get("/insurer/search/filter?size=" + size);
      getInsurerData(response);
      console.log("Filter", response);
    } catch (e) {
      toast.error("Erro ao carregar os dados da seguradora", e);
    }
  };

  const getInsurerData = (response) => {
    try {
      const data = response.data.content.map((insurer) => ({
        id: insurer.id,
        name: insurer.name,
        location: insurer.location,
        nuit: insurer.nuit,
        accountNumber: insurer.accountNumber,

        actions: (
          <OverflowMenu
          flipped
        >
          <OverflowMenuItem
            key="optionOne"
            itemText="Editar"
            onClick={() => navigate("../actualizar-seguradora/" + insurer.id,{state:{insurerId:insurer.id}})}
          />

        </OverflowMenu>
        ),
      }));
      setInsurers(data);
      console.log("Get", data);
      setCount(response.data.totalElements);
    } catch (e) {
      toast.error(e);
    }
  };
  const onPageChange = async ({ page, pageSize }) => {
    try {
      const pag = page - 1;
      console.log("pag", pag, "Pagesize", pageSize);

      const response = await http.get(
        "/insurer/search/filter?page=" + pag + "&size=" + pageSize
      );
      getInsurerData(response);
      console.log("Page Change", response);
    } catch (error) {
      toast.error(error);
    }
  };

  const insurerFilter = async (data) => {
    try {
      const response = await http.get(
        `/insurer/search/filter?name=${data.name}`
      );
      getInsurerData(response);
      console.log("Filter", response);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card title="Seguradoras">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-seguradora")}
          renderIcon={Add16}
        >
          Registar Seguradora
        </Button>
      </div>
      <DataTable
        rows={insurers}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(insurerFilter)}
                >
                  <Row>
                    {/* <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="name"
                        labelText=""
                        placeholder="Name do projecto"
                        {...register("name")}
                      />
                    </Column> */}
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default InsurerPage;
