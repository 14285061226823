import React, { useEffect, useContext } from "react";
import {
  Button,
  Form,
  CLoadingButton ,
  TextInput,
  Row,
  Column,
  ButtonSet,
} from "carbon-components-react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { insurerSchema } from "../../services/SchemaService";
import http from "../../http-common";
import { useState } from "react";
import { ArrowRight24, ArrowLeft24 } from "@carbon/icons-react";
import ClipLoader from "react-spinners/ClipLoader";

const InsurerForm = ({ mode, url, successMessage, insurerId }) => {
  const [opMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [state, setState] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [insurer, setInsurer] = useState({
    name: "",
    location: "",
    nuit: "",
    accountNumber: "",
  });

  const getSchema = () => {
    switch (mode) {
      case "CREATE":
        return insurerSchema;
      case "UPDATE":
        return insurerSchema;
      default:
        return insurerSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onBlur" });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (opMode === "UPDATE") {
        await http.post(url, data);
        toast.success("Seguradora Actualizada com sucesso!");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        navigate("../seguradoras");
      } else {
        await http.post(url, data);
        toast.success("Seguradora registada  com sucesso!");
        console.log("Dtaaa", data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        navigate("../seguradoras");
      }
      navigate("../seguradoras");

      toast.success(successMessage);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    console.log("state", insurerId);
    if (opMode === "UPDATE") {
      const fetchData = async () => {
        const result = null;
        try {
          setLoading(true);
          const result = await http.get(`/insurer/${insurerId}`);

          const fields = ["id", "name", "location", "nuit", "accountNumber"];
          fields.forEach((field) => setValue(field, insurer[field]));
          setInsurer(result.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [opMode]);

  useEffect(() => {
    reset(insurer);
  }, [insurer]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="name"
              labelText="Nome"
              maxLength={64}
              {...register("name")}
            />

            <p className="field-error">{errors.name?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="location"
              labelText="Localizacao"
              maxLength={64}
              {...register("location")}
            />
            <p className="field-error">{errors.location?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={6} lg={6} className="required">
            <TextInput
              id="nuit"
              labelText="Número do NUIT"
              {...register("nuit")}
              maxLength={9}
            />
            <p className="field-error">{errors.nuit?.message}</p>
          </Column>
          <Column sm={12} md={6} lg={6}>
            <TextInput
              id="accountNumber"
              labelText="Referencia"
              {...register("accountNumber")}
            />
            <p className="field-error">{errors.accountNumber?.message}</p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column sm={12} md={6} lg={6}>
            <ButtonSet>
              {!isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  Salvar
                </Button>
              )}
              {isLoading && (
                <Button
                  type="submit"
                  renderIcon={ArrowRight24}
                  className="mt-2 btn-fluid"
                >
                  <ClipLoader
                    isLoading={isLoading}
                    color={"#fff"}
                    size={20}
                    padding={"50px"}
                  />
                </Button>
              )}

              <Button
                onClick={() => navigate("../seguradoras")}
                renderIcon={ArrowLeft24}
                className="mt-2 btn-fluid"
              >
                Voltar
              </Button>
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default InsurerForm;
