// This should come from the database

const menu = (role) => {
  const menuItems = [
    {
      id: 4,
      label: "Utilizadores",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN"],
      items: [
        {
          id: 4.1,
          label: "Registar",
          link: "/admin/registar-utilizador",
          roles: ["ROLE_ADMIN"],
        },
        {
          id: 4.2,
          label: "Consultar",
          link: "/admin/utilizadores",
          roles: ["ROLE_ADMIN"],
        },
      ],
    },
    {
      id: 5,
      label: "Seguradora",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_SECRETARY"],
      items: [
        {
          id: 5.1,
          label: "Registar",
          link: "/admin/registar-seguradora",
          roles: ["ROLE_ADMIN", "ROLE_SECRETARY"],
        },
        {
          id: 5.2,
          label: "Consultar",
          link: "/admin/seguradoras",
          roles: ["ROLE_ADMIN", "ROLE_SECRETARY"],
        },
      ],
    },
    {
      id: 6,
      label: "Seguro",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_SECRETARY"],
      items: [
        {
          id: 5.1,
          label: "Consultar Seguros",
          link: "/admin/consultar-seguros",
          roles: ["ROLE_ADMIN", "ROLE_SECRETARY"],
        },
        {
          id: 5.2,
          label: "Consultar Taxas",
          link: "/admin/consultar-taxa",
          roles: ["ROLE_ADMIN", "ROLE_SECRETARY"],
        },
      ],
    },
    {
      id: 2,
      label: "Beneficiarios",
      icon: "Fade16",
      roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
      items: [
        {
          id: 2.1,
          label: "Registar",
          link: "/admin/beneficiary",
          roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
        },
        {
          id: 2.2,
          label: "Consultar",
          link: "/admin/ListaBeneficiarios",
          roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
        },
      ],
    },
    {
      id: 2,
      label: "Simulador",
      icon: "Fade16",
      roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
      items: [
        {
          id: 3.1,
          label: "Iniciar Simulacão",
          link: "/admin/simulador",
          roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
        },
        {
          id: 3.2,
          label: "Iniciar Simulacão para o Beneficiario",
          link: "/admin/simulador/beneficiario",
          roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
        },
        {
          id: 3.3,
          label: "Simulacões Efectuados",
          link: "/admin/listaDeSimulacoes",
          roles: ["ROLE_ADMIN", "ROLE_CLIENT", "ROLE_SECRETARY"],
        },
      ],
    },
  ];

  const userMenu = menuItems.filter((m) => m.roles.includes(role));
  userMenu.forEach((menu) => {
    menu.items = menu.items.filter((mi) => mi.roles.includes(role));
  });

  return userMenu;
};

export default menu;
