import { React, useState } from "react";
import Card from "../../components/Card/Card";
import InsuranceForm from "./InsuranceForm";
import { Tabs, Tab } from "carbon-components-react";
import { useParams, useLocation } from "react-router-dom";
import InsuranceTaxPage from "../InsuranceTax/InsuranceTaxPage";
import InsuranceBenefitsPage from "../InsuranceBenefits/InsuranceBenefitsPage";

const UpdateInsurancePage = () => {
  const params = useParams();
  const { state } = useLocation();
  return (
    <>
      <Card title="Actualizar Seguro">
        <InsuranceForm
          mode={"UPDATE"}
          url={"/insurance/" + state.insuranceId}
          successMessage={"Conta criada com sucesso"}
          onSuccessNavigateTo={"../seguros"}
          insuranceId={state.insuranceId}
          insurerId={state.insurerId}
        />
        <hr></hr>
        <Tabs className="Navmargin">
          <Tab label="Taxas">
            <InsuranceTaxPage
              insurerId={state.insurerId}
              insuranceId={state.insuranceId}
            />
          </Tab>
          <Tab label="Beneficios">
            <InsuranceBenefitsPage
              insurerId={state.insurerId}
              insuranceId={state.insuranceId}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default UpdateInsurancePage;
