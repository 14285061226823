import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Edit24 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  OverflowMenu,
  OverflowMenuItem,
} from "carbon-components-react";

import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";
import { toast } from "react-toastify";
const tableHeaders = [
  {
    header: "Descricao",
    key: "description",
  },
  {
    header: "Tipo",
    key: "type",
  },
  // {
  //   header: "Cont.",
  //   key: "containign",
  // },
  {
    header: "Operacoes",
    key: "actions",
  },
];

const InsuranceBenefitsPage = ({ insurerId, insuranceId }) => {
  const [insuranceBenefitis, setinsuranceBenefitis] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await http.get(`/insurance/benefits/`+insuranceId);
      getInsuranceBenefityData(response);
      console.log("Filter", response);
    } catch (e) {
      toast.error(e);
    }
  };

  const getInsuranceBenefityData = (response) => {
    try {
      const data = response?.data?.map((tax) => ({
        id: tax.id,
        description: tax.description,
        type: tax.type,
        containign: tax.containign,

        actions: (
          <OverflowMenu flipped>
            <OverflowMenuItem
              key="optionOne"
              itemText="Editar"
              onClick={() =>
                navigate("../actualizar-beneficio/" + tax.id, {
                  state: {
                    taxId: tax.id,
                    insuranceId: insuranceId,
                    insurerId: insurerId,
                  },
                })
              }
            />
          </OverflowMenu>
        ),
      }));
      setinsuranceBenefitis(data);
      setCount(response.data.totalElements);
    } catch (e) {
      toast.error(e);
    }
  };
  const onPageChange = async ({ page, pageSize }) => {
    try {
      const pag = page - 1;
      console.log("pag", pag, "Pagesize", pageSize);

      const response = await http.get(`/insurance/benefits/`+insuranceId);
      getInsuranceBenefityData(response);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card title="">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() =>
            navigate("../registar-beneficio/" + insurerId + "/" + insuranceId, {
              state: { insuranceId: insuranceId, insurerId: insurerId },
            })
          }
          renderIcon={Add16}
        >
          Registar Beneficios de Seguro
        </Button>
      </div>
      <DataTable
        rows={insuranceBenefitis}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar"></TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default InsuranceBenefitsPage;
