import * as yup from "yup";

const REQUIRED_FIELD_MESSAGE = "Campo obrigatório";
const INVALID_PHONE_MESSAGE = "Número de telefone inválido";
const INVALID_NUIT_MESSAGE = "O NUIT deve ter 9 digitos";
const INVALID_PASSWORD_MESSAGE = "A senha deve conter letras e digitos";
const NOT_A_NUMBER_MESSAGE = "Valor deve ser numérico";
const INVALID_MIN_NUMBER_MESSAGE = "Número não pode ser menor que zero";

const isRequiredString = yup
  .string()
  .typeError(REQUIRED_FIELD_MESSAGE)
  .required(REQUIRED_FIELD_MESSAGE);
const isRequiredStringWithoutNumber = yup
  .string()
  .typeError(REQUIRED_FIELD_MESSAGE)
  .required(REQUIRED_FIELD_MESSAGE)
  .test("no-number", "Não pode conter números", (value) => {
    if (typeof value !== "string") return false;
    return !/\d/.test(value);
  });
const isRequiredNumber = yup.number().required(REQUIRED_FIELD_MESSAGE);
const isNumber = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .typeError(NOT_A_NUMBER_MESSAGE)
  .nullable();
const isNullablePositiveInteger = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .typeError(NOT_A_NUMBER_MESSAGE)
  .integer("Número deve ser inteiro")
  .min(0, INVALID_MIN_NUMBER_MESSAGE)
  .nullable();
const isValidPhoneNumber = yup.string().required(REQUIRED_FIELD_MESSAGE)
.matches(/^8(2|3|4|5|6|7)[0-9]{7}$/, {
  message: INVALID_PHONE_MESSAGE,
  excludeEmptyString: true,
});
const isValidPhone = yup.string().required(REQUIRED_FIELD_MESSAGE)
.matches(/^8(4|5)[0-9]{7}$/, {
  message: INVALID_PHONE_MESSAGE,
  excludeEmptyString: true,
});

const isValidNuit = yup
  .string().required(REQUIRED_FIELD_MESSAGE)
  .matches(/^[0-9]{9}$/, {
    message: INVALID_NUIT_MESSAGE,
    excludeEmptyString: true,
  })
  .nullable();

  const numberOfTravelers = yup.object().shape({
    numero: yup
      .number()
      .max(24, 'O número não pode ser maior que 24')
      .required('O número é obrigatório'),
  });
  
const matriculaSchema = yup.string().test(REQUIRED_FIELD_MESSAGE,'Matrícula inválida', (value) => {
  
  const matriculaOrdinalRegex = /^[A-Z]{3}-\d{3}-[A-Z]{2}$/;
  const matriculaDiplomaticaRegex = /^[A-Z]{2}-\d{3}-[A-Z]$/;
  const matriculaAntigaRegex = /^M[A-Z]{2}-\d{2}-\d{2}$/;

  if (matriculaOrdinalRegex.test(value)) {
    return true;
  } else if (matriculaDiplomaticaRegex.test(value)) {
    return true;
  } else if (matriculaAntigaRegex.test(value)) {
    return true;
  }

  return false;
}).required();

const isValidaCarRegistration = yup
  .string()
  .required(REQUIRED_FIELD_MESSAGE)
  .test('carRegistration', 'Matrícula inválida', (value) => {
    return (
      /^[A-Z]{3}-\d{3}-[A-Z]{2}$/.test(value) ||
      /^[A-Z]{2}-\d{3}-M-\d{2}$/.test(value) ||
      /^M[A-Z]{2}-\d{2}-\d{2}$/.test(value)
    );
  });











  const validnumberOfTravelers = yup
  .number()
  .required(REQUIRED_FIELD_MESSAGE)
  .positive('O número de viajantes deve ser positivo.')
  .integer('O número de viajantes deve ser um número inteiro.')
  .max(24, 'O número de viajantes não pode ser maior que 24.')
  .nullable();



const isValidEmail = yup.string().email("Email inválido").nullable();
const isValidDate = yup
  .string().required(REQUIRED_FIELD_MESSAGE)
  .matches(
    /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
    "Data inválida. Use o formato dd/mm/aaaa"
  );

const roleRules = yup.object({
  roleCode: yup.string().required(REQUIRED_FIELD_MESSAGE),
});

const passwordRules = yup.object({
  password: yup
    .string()
    .transform((x) => (x === "" ? undefined : x))
    .required(REQUIRED_FIELD_MESSAGE)
    .min(6, "A senha deve ter no mínimo 6 caracteres")
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/,
      INVALID_PASSWORD_MESSAGE
    ),
  passwordConfirm: yup
    .string()
    .transform((x) => (x === "" ? undefined : x))
    .required(REQUIRED_FIELD_MESSAGE)
    .oneOf([yup.ref("password")], "As senhas devem ser iguais"),
});

const userSchema = yup.object({
  firstname: isRequiredString,
  lastname: isRequiredString,
  username: isRequiredString,
  email: isValidEmail,
  password: isRequiredString,
  bankId: isNumber,
});

const clientSchema = yup.object({
  // cellNumber:isValidPhone,
  // phoneNumber:isValidPhoneNumber,
  firstName: isRequiredStringWithoutNumber,
  surName: isRequiredStringWithoutNumber,
  nuit: isValidNuit,
  contact: isValidPhoneNumber,
  email: isValidEmail,
  birthDate: isValidDate,
  gender: isRequiredStringWithoutNumber,
  address: isRequiredString,
  profession: isRequiredStringWithoutNumber,
});

// const loanSchema = yup.object({
//   purpose: isRequiredString,
//   interestRate: isRequiredNumber.concat(isNumber),
//   // taxId: isRequiredNumber.concat(isNumber),
//   // clientId: isRequiredNumber.concat(isNumber),
//   numberOfMonths: isRequiredNumber.concat(isNumber),
//   clientId: isRequiredNumber.concat(isNumber),
// });

const basicApplicationSchema = yup.object({
  firstName: isRequiredString,
  lastName: isRequiredString,
  nuit: isValidNuit,
  email: isValidEmail,
  birthDate: isRequiredString.concat(isValidDate),
  gender: isRequiredString,
  identificationDocTypeCode: isRequiredString,
  otherIdentificationDocType: yup
    .string()
    .when("identificationDocTypeCode", {
      is: "0",
      then: yup.string().required("Especifique o tipo"),
    })
    .nullable(true),
  identificationDocNumber: isRequiredString,
  phoneNumber1: isRequiredString.concat(isValidPhoneNumber),
  phoneNumber2: isValidPhoneNumber,
  altContactPersonPhoneNumber: isValidPhoneNumber,
  numberOfExpectedWorkers: isNullablePositiveInteger,
  numberOfExpectedFemaleWorkers: isNullablePositiveInteger,
  numberOfExpectedMaleWorkers: isNullablePositiveInteger,
  numberOfExpectedWorkersAged18To35: isNullablePositiveInteger,
  numberOfExistingWorkers: isNullablePositiveInteger,
  numberOfExistingMaleWorkers: isNullablePositiveInteger,
  numberOfExistingFemaleWorkers: isNullablePositiveInteger,
  numberOfExistingMaleWorkersAged18To35: isNullablePositiveInteger,
  numberOfExistingFemaleWorkersAged18To35: isNullablePositiveInteger,
});

const submitApplicationSchema = yup.object({
  altContactPersonName: isRequiredString,
  altContactPersonPhoneNumber: isRequiredString,
  altContactPersonRelationship: isRequiredString,
  provinceId: isRequiredString,
  districtId: isRequiredString,
  neighborhood: isRequiredString,
  street: isRequiredString,
  addressTypeCode: isRequiredString,
  academicLevelCode: isRequiredString,
  competitorData: isRequiredString,
  hasBusinessCurrently: isRequiredString,
  businessIdea: isRequiredString,
  ideaProductOrService: isRequiredString,
  targetCustomerProfile: isRequiredString,
  workers: isRequiredString,
  numberOfExpectedWorkers: isRequiredNumber.concat(isNumber),
  numberOfExpectedFemaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExpectedMaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExpectedWorkersAged18To35: isRequiredNumber.concat(isNumber),
  howWillEnsureEqualEmploymentOpportunity: isRequiredString,
});

const submitBusinessProfileSchema = yup.object({
  businessProductOrService: isRequiredString,
  numberOfExistingWorkers: isRequiredNumber.concat(isNumber),
  numberOfExistingMaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExistingFemaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExistingMaleWorkersAged18To35: isRequiredNumber.concat(isNumber),
  numberOfExistingFemaleWorkersAged18To35: isRequiredNumber.concat(isNumber),
  workFacilitiesProvinceId: isRequiredString,
  workFacilitiesDistrictId: isRequiredString,
  workFacilitiesLocality: isRequiredString,
  workFacilitiesNeighborhood: isRequiredString,
  workFacilitiesStreet: isRequiredString,
  ownWorkFacilities: isRequiredString,
});

const submitBankSchema = yup.object({
  bankName: isRequiredString,
  location: isRequiredString,
});

const submitContactchema = yup.object({
  ownerRef: isRequiredString,
  emailFrom: isValidEmail,
  subject: isRequiredString,
  text: isRequiredString,
});

const submitTaxSchema = yup.object({
  bankId: isRequiredString,
  // bankName: isRequiredString,
  interestRate: isRequiredString,
  taxDate: isRequiredString,
  typeOfBank: isRequiredString,
  categoryMonthId: isNumber,
  categoryId: isNumber,
});

const carSimulationSchema = yup.object({
  carRegistration: matriculaSchema,
});

const submitSimulationSchema = yup.object({
  simulationDate: isRequiredString,
  clientId: isNumber,
  purpose: "",
  yearManufacture: isNumber,
  carRegistration: isValidaCarRegistration,
  brand: "",
  model: "",
  capacity: isNumber,
  typeVehicle: "",
  vehicleValue: isNumber,
  namePersonInCharge: "",
  typeHousing: "",
  numberWorkers: isNumber,
  activity: "",
  grossMonthlySalary: isNumber,
  monthlyAllowance: isNumber,
  destination: "",
  address: "",
  merchandiseVolumePerMonth: "",
  securityMeasures: "",
  accidentRecord: "",
  merchandiseInventory: "",
  buildingValue: "",
  numberHouseholds: isNumber,
  housingValue: isRequiredNumber.concat(isNumber),
  fillingValue: isRequiredNumber.concat(isNumber),
  insuranceAgainst: "",
  numberOfTravelers: numberOfTravelers
});

const submitLoanRequestSchema = yup.object({
 
            clientId: isNumber,
  homeAddress: isRequiredString,
  maritalStatus: isRequiredString,
  typeOfActivity: "",
  seniorityActivity: "",
  institutionName: "",
  institutionAddress: "",
  name: isRequiredString,
  financingId: isNumber,
  bankId: isRequiredString,
  businessType: "",
  applicantName: "",
  phone: "",
  nuitNumber: "",
  creditType: "",
  subscriber: "",
  representantName: "",
  relationshipWithRepresentant: "",
  monthlyIncome: "",
  owner: "",
  requester: "",
  other: "",
  otherActivities: "",
  monthlyIncomeOfOthersActivities: "",
  position: "",
  institutionPhone: "",
  liquidSalary: "",
  otherRegularIncome: "",
});

const grievanceSchema = yup.object({
  type: isRequiredString,
  subject: isRequiredString,
  description: isRequiredString,
});

//MIc
const insurerSchema = yup.object({
  name: isRequiredString,
  location: isRequiredString,
  nuit: isValidNuit,
});

const insuranceTaxSchema = yup.object({
  taxPer: isRequiredNumber,
});

const paymentsScheme = yup.object({
  phoneNumber: isValidPhoneNumber,
  bankNumber: isNumber,
  codeNumber: isNumber,
  bankaccount: isNumber,
  NameOfTheAccountHolder: isRequiredString,
  BeneficiarysName: isRequiredString,
  code: isNumber,
  numberAccount: isNumber,
  phoneAmount: isNumber,
  bankAmount: isNumber,
  cellNumber: isValidPhoneNumber
});

//MIc
const insuranceSchema = yup.object({
  type: isRequiredString,
  description: isRequiredString,
});

const mpesaSchema = yup.object({
  phoneNumber: isValidPhoneNumber,
});

export {
  //KYCSchema,
  userSchema,
  clientSchema,
  submitBankSchema,
  submitLoanRequestSchema,
  submitTaxSchema,
  roleRules,
  passwordRules,
  basicApplicationSchema,
  submitBusinessProfileSchema,
  submitApplicationSchema,
  grievanceSchema,
  submitContactchema,
  submitSimulationSchema,
  insurerSchema,
  insuranceSchema,
  insuranceTaxSchema,
  paymentsScheme,
  mpesaSchema,
};
